// eslint-disable-next-line @typescript-eslint/no-unused-vars
interface String {
    b64encode(): string
    b64decode(): string
    toObject<T>(): T
}

String.prototype.b64encode = function (this: string): string {
    return btoa(unescape(encodeURIComponent(this)))
}

String.prototype.b64decode = function (this: string): string {
    return decodeURIComponent(escape(atob(this)))
}
String.prototype.toObject = function <T>(this: string) {
    return JSON.parse(this) as T
}
