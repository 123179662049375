import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import Loby from '@/views/Loby.vue'

export enum ROUTES {
    GAME = 'game',
    GAME_DEFAULT = 'game_default',
    LOGIN = 'login',
    LOBY = 'loby',
    NIU_NIU_GAME = 'game2',
    SERVER_SELECTION = 'select-server-line'
}

const routes: Array<RouteRecordRaw> = [
    {
        path: '/',
        name: ROUTES.LOBY,
        component: Loby
    },
    {
        path: `/${ROUTES.LOGIN}`,
        name: ROUTES.LOGIN,
        component: () => import('@/views/Login.vue')
    },
    {
        path: `/${ROUTES.GAME}`,
        name: ROUTES.GAME,
        props: (_route) => ({
            desk: parseInt(String(_route.query.desk ?? -1)),
            gameType: parseInt(String(_route.query.gameType ?? -1)),
            xian: parseInt(String(_route.query.xian ?? -1))
        }),
        component: () => import('@/views/game/index.vue'),
        children: [
            {
                path: '',
                name: ROUTES.GAME_DEFAULT,
                component: () => import('@/views/game/Default.vue')
            }
        ]
    },
    {
        path: `/${ROUTES.SERVER_SELECTION}`,
        name: ROUTES.SERVER_SELECTION,
        component: () => import('@/views/ServerSelect.vue')
    }
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

export default router
