export class RoadMapItem {
    type = 0
    size = 0
    pairs: number[] = []

    constructor(type = 0) {
        this.type = type
    }

    addSize() {
        this.size += 1
    }

    pop() {
        if (this.size > 0) this.size -= 1
    }
}
