import { Directive } from '@vue/runtime-core'

const clickOutside: Directive = {
    mounted(el, binding, vnode) {
        let ctr = 0
        // asign event to the element
        el.clickOutsideEvent = function(event: Event) {
            if (!(el === event.target || el.contains(event.target))) {
                if (ctr !== 0) binding.value(event)

                ctr += 1
            }
        }
        // register click and touch events
        document.body.addEventListener('click', el.clickOutsideEvent)
        document.body.addEventListener('touchstart', el.clickOutsideEvent)
    },
    unmounted(el) {
        // unregister click and touch events before the element is unmounted
        document.body.removeEventListener('click', el.clickOutsideEvent)
        document.body.removeEventListener('touchstart', el.clickOutsideEvent)
    }
}

export default clickOutside
