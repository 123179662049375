
import {
    Emitter,
    computed,
    defineComponent,
    inject,
    onBeforeUnmount,
    onMounted,
    ref
} from 'vue'
import { iconMenu } from '@/components/icon'
import { pcListItem, mobileListItem } from '@/components/loby'
import { useRouter } from 'vue-router'
import { ROUTES } from '@/router'
import {
    DialogBoxName,
    MessageBoxActionType,
    MessageBoxEvent
} from '@/types/MessageBox'
import { ACT, EventBuss, OtherApiParams } from '@/types/global'
import { useStore } from '@/store'
// import { VTextMarquee } from 'vue-text-marquee'
import { AppActions, AppMutation } from '@/store/types'
import { useI18n } from 'vue-i18n'
import { getValueByKey, getValueOfKeyValue } from '@/utils/api'
import { Desk } from '@/types/Desk'
import { AxiosSource } from '@/api'
import axios from 'axios'

export default defineComponent({
    components: {
        'icon-menu': iconMenu,
        // 'icon-user': iconUser,
        // 'icon-curency': iconCurency,
        'pc-list-item': pcListItem,
        // 'pc-list-item-niu': pcListItemNiu,
        'mobile-list-item': mobileListItem
        // 'mobile-list-item-niu': mobileListItemNiu,
        // 'marquee-text': VTextMarquee
    },
    setup() {
        const emitter = inject('emitter') as Emitter
        const { t } = useI18n()
        const store = useStore()

        const router = useRouter()
        const noticeKey = ref(`loby-notice-key-${new Date().getTime()}`)

        const desks = ref<Desk[]>([])

        const activeDesk = ref(-1)

        const PULL_INTERVAL = 3000
        let serviceTimeOut: boolean | number | any = false
        let failureCounter = 0

        const GameType = {
            BACCARAT: 4,
            DRAGON_TIGER: 2,
            DEAL_FIRST: 5
        }

        const activeTab = ref(GameType.BACCARAT)

        onMounted(() => {
            loadData()
        })

        onBeforeUnmount(() => {
            stopPullingData()
        })

        const loadData = (_forceReload = false) => {
            if (!_forceReload) store.commit(AppMutation.SHOW_LOADING)

            const _params = {
                ...OtherApiParams,
                act: ACT.MULTIPLE_DESK_INFO,
                sessionID: token.value,
                username: user.value,
                gameType: activeTab.value
            }

            store
                .dispatch(AppActions.FETCH_DATA, _params)
                .then((response: string) => {
                    failureCounter = 0
                    if (!_forceReload) store.commit(AppMutation.HIDE_LOADING)

                    const err = getValueByKey(response, 'err')
                    if (err) {
                        // expire session kick out
                        handleExpiredSession()
                    } else {
                        desks.value = Desk.ParseList(response)
                        if (response.includes('$UserPoint=')) {
                            const _userPointValuePair =
                                response.split('$')[desks.value.length]
                            const _coin = parseInt(
                                getValueOfKeyValue(_userPointValuePair) ?? '0'
                            )
                            store.commit(AppMutation.SET_COIN, _coin)
                        }

                        serviceTimeOut = setTimeout(() => {
                            loadData(true)
                        }, PULL_INTERVAL)
                    }
                })
                .catch((e) => {
                    if (!axios.isCancel(e)) {
                        failureCounter += 1
                        if (!_forceReload) {
                            store.commit(AppMutation.HIDE_LOADING)
                            emitter.emit(
                                EventBuss.TOAST_MESSAGE,
                                t('prompt.networkerror')
                            )
                        }
                        if (failureCounter > 3) {
                            // stop pulling service
                            stopPullingData()
                            emitter.emit(
                                EventBuss.TOAST_MESSAGE,
                                t('prompt.networkerror')
                            )
                            store.commit(AppMutation.CLEAR_ALL)
                            router.push({ name: ROUTES.LOGIN })
                            return
                        }
                        serviceTimeOut = setTimeout(() => {
                            loadData(true)
                        }, PULL_INTERVAL)
                    }
                })
        }

        const stopPullingData = () => {
            if (AxiosSource) {
                // cancell all axios pending request
                AxiosSource.Source.cancel()
                AxiosSource.Source = axios.CancelToken.source()
            }

            if (typeof serviceTimeOut === 'number') {
                clearTimeout(serviceTimeOut)
                serviceTimeOut = false
            }
        }

        const handleExpiredSession = () => {
            stopPullingData()
            emitter.emit(EventBuss.TOAST_MESSAGE, t('prompt.sessionexpired'))
            store.commit(AppMutation.CLEAR_ALL)
            router.push({ name: ROUTES.SERVER_SELECTION })
        }

        const changeTab = (tab: number) => {
            if (activeTab.value !== tab) {
                stopPullingData()
                desks.value = []
                activeTab.value = tab

                store.commit(AppMutation.SHOW_LOADING)

                setTimeout(() => {
                    loadData()
                }, 1000)
            }
        }

        const enterTable = (desk: Desk) => {
            // router.push({ name: ROUTES.GAME_DEFAULT })
            if (activeDesk.value === desk.tableNo) activeDesk.value = -1
            else activeDesk.value = desk.tableNo
        }

        const logOut = () => {
            stopPullingData()
            const params = {
                ...OtherApiParams,
                username: user.value,
                act: ACT.LOG_OUT
            }

            store.commit(AppMutation.SHOW_LOADING)
            store
                .dispatch(AppActions.FETCH_DATA, params)
                .then((response: string) => {
                    store.commit(AppMutation.HIDE_LOADING)
                    if (response === 'result=ok') {
                        store.commit(AppMutation.CLEAR_ALL)
                        router.push({ name: 'select-server-line' })
                    } else {
                        emitter.emit(
                            EventBuss.TOAST_MESSAGE,
                            t('prompt.networkerror')
                        )
                    }
                })
                .catch(() => {
                    store.commit(AppMutation.HIDE_LOADING)
                    emitter.emit(
                        EventBuss.TOAST_MESSAGE,
                        t('prompt.networkerror')
                    )
                })
        }

        const showDialog = (name: DialogBoxName) => {
            const _evt: MessageBoxEvent = {
                type: MessageBoxActionType.open,
                name
            }

            emitter.emit(EventBuss.DIALOG, _evt)
        }
        const goToRule = () => {
            window.open(
                'http://n1.bb3336.com/resource/rules/rule_home.html',
                '_blank'
            )
        }

        const visitCustumerService = () => {
            window.open(process.env.VIP_SERVICE, '_blank')
        }

        const toggleFullScreen = () => {
            emitter.emit(EventBuss.REQUEST_FULL_SCREEN)
        }

        const deviceOrientation = computed(
            (): 'landscape' | 'portrait' => store.getters['deviceOrientation']
        )

        const user = computed((): string => store.getters['user'])
        const coin = computed((): number => store.getters['coin'])
        const notice = computed((): string => store.getters['lobyNotice'])
        const token = computed((): string => store.getters['token'])
        const isFull = computed(() => store.getters['isfullScreen'])

        return {
            isFull,
            desks,
            activeTab,
            deviceOrientation,
            noticeKey,
            user,
            coin,
            notice,
            GameType,
            activeDesk,
            logOut,
            changeTab,
            enterTable,
            showDialog,
            t,
            toggleFullScreen,
            goToRule,
            visitCustumerService
        }
    }
})
