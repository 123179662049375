import axios from 'axios'
import { getCookieValue } from '.'
import { CookiesToken } from '@/types/CookiesToken'
import { store } from '@/store'

const request = axios.create({
    baseURL: `${process.env.API_URL}/${process.env.API_EXT}`,
    timeout: 8000
})

request.interceptors.request.use(
    (config) => {
        const line = parseInt(getCookieValue(CookiesToken.LINE) ?? '0')
        const ext = process.env.API_EXT

        if (line > 0) {
            if (line === 1)
                config.baseURL = `${process.env.API_URL_LINE1}/${ext}`
            else if (line === 2)
                config.baseURL = `${process.env.API_URL_LINE2}/${ext}`
            else if (line === 3)
                config.baseURL = `${process.env.API_URL_LINE3}/${ext}`
        } else if (line === -1) {
            const customIp = store.getters['serverLineStr'] as string
            config.baseURL = `${customIp}/${ext}`
        }
        return config
    },
    (error) => {
        return Promise.reject(error)
    }
)

request.interceptors.response.use(
    (response) => {
        return response
    },
    (error) => {
        return Promise.reject(error)
    }
)

export default request
