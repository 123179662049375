export const Lang = ['en', 'kor', 'cn']

export enum EventBuss {
    WINDOW_RESIZED = 'WINDOW_RESIZED',
    DIALOG = 'DIALOG',
    REQUEST_FULL_SCREEN = 'REQUEST_FULL_SCREEN',
    TOAST_MESSAGE = 'TOAST_MESSAGE',
    PAGE_ON_VISIBLE = 'PAGE_ON_VISIBLE',
    SHOW_GAME_RESULT = 'SHOW_GAME_RESULT',
    TOAST_IN_GAME_MESSAGE = 'TOAST_IN_GAME_MESSAGE'
}

/**
 * API ACT params
 */
export const ACT = {
    LOGIN: 1,
    TRIAL: 11,
    LOG_OUT: 17,
    MULTIPLE_DESK_INFO: 2,
    GET_NOTICE_AND_LIMIT: 9,
    SINGLE_DESK_INFO: 3,
    CARD_INFO: 10,
    DO_DT_BET: 7,
    DO_BAC_BET: 6,
    CHANGE_PASS: 12,
    GET_LOG: 13,
    GET_LOG_DETAILS: 15,
    MULTIPLE_BET_DESK_INFO: 18,
    REGISTER: 19,
    NIU_CARD_INFO: 100,
    DO_NIU_NIU_BET: 66,
    VIDEO_INFOS: 18
}

export const OtherApiParams = {
    jm: 1,
    skey: process.env.API_SKEY
}

/**
 * GameState Of a Desk
 */
export const GameRoundState = {
    CLOSED: 1,
    END_OF_ROUND: 4
}

/**
 * Game Result
 */
export const RESULT = {
    RED: 1,
    BLUE: 3,
    GREEN: 2,
    DRAGON: 4,
    TIGER: 6
}

/**
 * Bet Type
 */
export type BET_TYPES =
    | 'BANKER'
    | 'PLAYER'
    | 'TIE'
    | 'PLAYER_PAIR'
    | 'BANKER_PAIR'
    | 'DRAGON'
    | 'TIGER'
    | 'DT_TIE'
    | 'SUPER_6'
    | 'NA'

export const BET = {
    CODE: {
        OK: -1,
        INSUFICIENT_BALANCE: 0,
        NO_BET_AREA_SELECTED: 1,
        BELOW_MINIMUM: 2,
        ABOVE_MAXIMUM: 3,
        THERE_IS_NO_BET: 4,
        BET_FAILED_BET_AGAIN: 5
    },
    MESSAGE: {
        ABOVE_MAXIMUM: 'prompt.exceedingbet',
        BELOW_MINIMUM: 'prompt.bellowlimit',
        THERE_IS_NO_BET: 'prompt.nobetyet',
        BET_FAILED_BET_AGAIN: 'prompt.betfailed',
        NO_BET_AREA_SELECTED: 'prompt.selectbetareafirst',
        INSUFICIENT_BALANCE: 'prompt.insufficientbalance'
    }
}

export const BET_TYPE = {
    BANKER: 1,
    PLAYER: 2,
    TIE: 3,
    PLAYER_PAIR: 4,
    BANKER_PAIR: 5,
    DRAGON: 6,
    TIGER: 7,
    DT_TIE: 8,
    SUPER_6: 9
}

export const NUI_BET_TYPE = {
    P1_EVEN: 'P1_EVEN',
    P2_EVEN: 'P2_EVEN',
    P3_EVEN: 'P3_EVEN',
    P1_DOUBLE: 'P1_DOUBLE',
    P2_DOUBLE: 'P2_DOUBLE',
    P3_DOUBLE: 'P3_DOUBLE',
    P1_SUPER: 'P1_SUPER',
    P2_SUPER: 'P2_SUPER',
    P3_SUPER: 'P3_SUPER'
}

export const languageOptions: { code: string; name: string }[] = [
    {
        code: 'cn',
        name: '简体中文'
    },
    {
        code: 'en',
        name: 'English'
    },
    {
        code: 'kor',
        name: '한국어'
    }
]
