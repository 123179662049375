import { Assets, ref } from 'vue'

export function useAssetPreload() {
    //#region Mobile Assets
    const imgSrc: Assets[] = []
    //#endregion

    //#region  PC Assets
    const pcAssets: Assets[] = []
    //#endregion

    //#region Common Assets

    //#endregion LB Assets
    const lbAssets: Assets[] = [
        {
            name: 'visa-img1@2x',
            src: require('@/assets/imgs/lb/visa-img1@2x.png'),
            type: 'image'
        },
        {
            name: 'visa-img2@2x',
            src: require('@/assets/imgs/lb/visa-img2@2x.png'),
            type: 'image'
        }
    ]
    //#endregion
    const commonAssets: Assets[] = [
        {
            name: 'logo',
            src: require('@/assets/imgs/logo.png'),
            type: 'image'
        },
        {
            name: 'loader',
            src: require('@/assets/imgs/loader.gif'),
            type: 'image'
        },
        {
            name: 'chips_sprites_sheet',
            src: require('@/assets/imgs/chips_sprites_sheet.png'),
            type: 'image'
        },
        {
            name: 'chip_selected',
            src: require('@/assets/imgs/chip_selected.png'),
            type: 'image'
        },
        {
            name: 'flags',
            src: require('@/assets/imgs/flags.png'),
            type: 'image'
        },
        {
            name: 't_coin',
            src: require('@/assets/imgs/t_coin.png'),
            type: 'image'
        },
        {
            name: 'h_clubs',
            src: require('@/assets/imgs/h_clubs.png'),
            type: 'image'
        },
        {
            name: 'h_diamonds',
            src: require('@/assets/imgs/h_diamonds.png'),
            type: 'image'
        },
        {
            name: 'h_hearts',
            src: require('@/assets/imgs/h_hearts.png'),
            type: 'image'
        },
        {
            name: 'h_spades',
            src: require('@/assets/imgs/h_spades.png'),
            type: 'image'
        },
        {
            name: 'v_clubs',
            src: require('@/assets/imgs/v_clubs.png'),
            type: 'image'
        },
        {
            name: 'v_diamonds',
            src: require('@/assets/imgs/v_diamonds.png'),
            type: 'image'
        },
        {
            name: 'v_hearts',
            src: require('@/assets/imgs/v_hearts.png'),
            type: 'image'
        },
        {
            name: 'v_spades',
            src: require('@/assets/imgs/v_spades.png'),
            type: 'image'
        }
    ]
    //#endregion

    const assets = [...imgSrc, ...pcAssets, ...commonAssets]
    const refVar = ref<{
        isLoaded: boolean
        loadCount: number
        assetCount: number
    }>({ isLoaded: false, loadCount: 0, assetCount: assets.length })

    const LoadAssest = (_assets: Assets) => {
        return new Promise((resolve, reject) => {
            if (_assets.type === 'image') {
                const img = new Image()
                img.src = _assets.src
                img.onload = () => {
                    if (refVar.value) {
                        refVar.value.loadCount++
                        if (
                            refVar.value.assetCount === refVar.value.loadCount
                        ) {
                            refVar.value.isLoaded = true
                        }
                    }
                    resolve(img)
                }

                img.onerror = img.onabort = () => {
                    if (refVar.value) refVar.value.loadCount++
                    reject(_assets)
                }
            }
        })
    }

    const PreloadAssets = () => {
        assets.map((imgs) => LoadAssest(imgs).catch((err) => err))
    }

    return {
        refVar,
        PreloadAssets
    }
}
