import { fetch } from '@/api'
import { CookiesToken } from '@/types/CookiesToken'
import { getCookieValue, parseBool, setCookieValue } from '@/utils'
import { State, TableLimit, UserSessions, UserState } from 'vue'
import { ActionTree, GetterTree, Module, MutationTree } from 'vuex'
import { AppActions, AppMutation } from '../types'
import { ungzip } from 'pako'
import { Dictionary } from '@/types/dictionary'
import axios, { CancelToken, CancelTokenSource } from 'axios'

const state: UserState = {
    username: getCookieValue(CookiesToken.USER_NAME)?.b64decode(),
    token: getCookieValue(CookiesToken.TOKEN)?.b64decode(),
    customChipValue: parseInt(
        getCookieValue(CookiesToken.CUSTOM_CHIP_VALUE) || '0'
    ),
    coin: parseInt(getCookieValue(CookiesToken.BALANCE) || '0'),
    limits: getCookieValue(CookiesToken.LIMITS)?.toObject<TableLimit[]>() || [],
    lobyNotice: getCookieValue(CookiesToken.LOBY_NOTICE) || '',
    limit:
        getCookieValue(CookiesToken.LIMIT)?.toObject<TableLimit>() || undefined,
    chips: getCookieValue(CookiesToken.CHIPS)?.toObject<number[]>() || [],
    ingameNotice: getCookieValue(CookiesToken.IN_GAME_NOTICE) || '',
    resultCache: new Dictionary<string>(),
    vipTables:
        getCookieValue(CookiesToken.VIP_TABLES)?.toObject<number[]>() ?? [],
    bcTables:
        getCookieValue(CookiesToken.BAC_TABLES)?.toObject<number[]>() ?? [],
    dtTables:
        getCookieValue(CookiesToken.DT_TABLES)?.toObject<number[]>() ?? [],
    xmRate: parseInt(getCookieValue(CookiesToken.XM_RATE) || '0')
}

const mutations: MutationTree<UserState> = {
    [AppMutation.SET_USER](state, payload: string) {
        state.username = payload
        setCookieValue(CookiesToken.USER_NAME, payload.b64encode())
    },
    [AppMutation.SET_CUSTOM_CHIP_VAALUE](state, payload: number) {
        state.customChipValue = payload
        setCookieValue(CookiesToken.CUSTOM_CHIP_VALUE, String(payload))
    },
    [AppMutation.SET_CHIPS](state, payload: number[]) {
        state.chips = payload
        setCookieValue(CookiesToken.CHIPS, JSON.stringify(payload))
    },
    [AppMutation.INIT_USER_SESSTION](state, payload: UserSessions) {
        state.token = payload.token
        state.username = payload.username
        state.coin = payload.point
        state.limits = payload.limits
        state.lobyNotice = payload.notice

        state.bcTables = payload.bacDesks
        state.vipTables = payload.vipDesks
        state.dtTables = payload.draDesks
        state.xmRate = payload.xmRate

        setCookieValue(CookiesToken.TOKEN, state.token.b64encode())
        setCookieValue(CookiesToken.USER_NAME, state.username.b64encode())
        setCookieValue(CookiesToken.BALANCE, String(state.coin))
        setCookieValue(CookiesToken.LIMITS, JSON.stringify(state.limits))
        setCookieValue(CookiesToken.LOBY_NOTICE, payload.notice)
        setCookieValue(
            CookiesToken.BAC_TABLES,
            JSON.stringify(payload.bacDesks)
        )
        setCookieValue(
            CookiesToken.VIP_TABLES,
            JSON.stringify(payload.vipDesks)
        )
        setCookieValue(CookiesToken.DT_TABLES, JSON.stringify(payload.draDesks))
        setCookieValue(CookiesToken.XM_RATE, JSON.stringify(state.xmRate))
    },
    [AppMutation.SET_COIN](state, payload: number) {
        state.coin = payload
        setCookieValue(CookiesToken.BALANCE, String(payload))
    },
    [AppMutation.SET_LIMIT](state, payload: TableLimit) {
        state.limit = payload
        setCookieValue(CookiesToken.LIMIT, JSON.stringify(payload))
    },
    [AppMutation.SET_IN_GAME_NOTICE](state, payload: string) {
        state.ingameNotice = payload
        setCookieValue(CookiesToken.IN_GAME_NOTICE, payload)
    },
    [AppMutation.ADD_RESULT_TO_CACHE](
        state,
        payload: { key: string; value: string }
    ) {
        state.resultCache.Add(payload.key, payload.value)

        if (state.resultCache.Count() > 15) {
            state.resultCache.Remove(state.resultCache.Keys()[0])
        }
    }
}

const actions: ActionTree<UserState, State> = {
    [AppActions.FETCH_DATA]({ ...args }, params): Promise<any> {
        return new Promise<any>((resolve, reject) => {
            fetch(params)
                .then((response) => {
                    const compressedData = new Uint8Array(response.data)
                    const unzippedData = ungzip(compressedData, {
                        to: 'string'
                    })
                    resolve(unzippedData)
                })
                .catch((e) => {
                    if (axios.isCancel(e)) {
                        console.log('api call is cancelled')
                    }
                    reject(e)
                })
        })
    },
    [AppActions.FETCH_VIDEOS](
        { ...args },
        params: { params: any; token: CancelTokenSource }
    ) {
        return new Promise<any>((resolve, reject) => {
            fetch(params.params, params.token)
                .then((response) => {
                    const compressedData = new Uint8Array(response.data)
                    const unzippedData = ungzip(compressedData, {
                        to: 'string'
                    })
                    resolve(unzippedData)
                })
                .catch((e) => {
                    if (axios.isCancel(e)) {
                        console.log('api call is cancelled')
                    }
                    reject(e)
                })
        })
    },
    [AppActions.FETCH_DATA_C_TOKEN](
        { ...args },
        params: { params: any; token: CancelTokenSource }
    ) {
        return new Promise<any>((resolve, reject) => {
            fetch(params.params, params.token)
                .then((response) => {
                    const compressedData = new Uint8Array(response.data)
                    const unzippedData = ungzip(compressedData, {
                        to: 'string'
                    })
                    resolve(unzippedData)
                })
                .catch((e) => {
                    if (axios.isCancel(e)) {
                        console.log('api call is cancelled')
                    }
                    reject(e)
                })
        })
    }
}

const getters: GetterTree<UserState, State> = {
    user: (state) => state.username,
    customChipValue: (state) => state.customChipValue,
    token: (state) => state.token,
    coin: (state) => state.coin,
    lobyNotice: (state) => state.lobyNotice,
    limits: (state) => state.limits,
    limit: (state) => state.limit,
    chips: (state) => state.chips,
    ingameNotice: (state) => state.ingameNotice,
    resultCache: (state) => state.resultCache,
    bacDesks: (state) => state.bcTables,
    draDesks: (state) => state.dtTables,
    vipDesk: (state) => state.vipTables,
    xmRate: (state) => state.xmRate
}

export const user: Module<UserState, State> = {
    state,
    mutations,
    actions,
    getters
}
