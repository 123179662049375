import { CookiesToken } from '@/types/CookiesToken'
import { getBrowserLanguage } from '@/utils'
import { AppState, InjectionKey, State, UserState } from 'vue'
import { createStore, Store, useStore as baseUseStore } from 'vuex'

import { app } from './modules/app'
import { user } from './modules/user'
import { AppMutation } from './types'
export const key: InjectionKey<Store<State>> = Symbol()

export const store = createStore<State>({
    state: {
        version: '1.0.1',
        root: true
    },
    mutations: {
        [AppMutation.CLEAR_ALL](state) {
            const _userState = user.state as UserState
            const _appState = app.state as AppState
            _userState.token = undefined
            _userState.username = undefined
            _userState.limit = undefined
            _userState.coin = 0
            _userState.customChipValue = 0
            _userState.lobyNotice = ''
            _userState.ingameNotice = ''
            _userState.chips = []
            _userState.xmRate = 0

            _appState.lineStr = ''
            _appState.line = 0

            window.sessionStorage.clear()
        }
    },
    actions: {},
    modules: {
        app,
        user
    }
})

export function useStore(): Store<State> {
    return baseUseStore(key)
}
