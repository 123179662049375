enum MessageBoxActionType {
    close = 'close',
    open = 'open'
}

type MessageBoxEvent = {
    type: MessageBoxActionType
    message?: string | unknown
    name: DialogBoxName
}

type DialogBoxName =
    | 'setting'
    | 'others'
    | 'lang'
    | 'edit-custom-chip'
    | 'edit-password'
    | 'bet-history'
    | 'rule'
    | 'media-setting'

export { MessageBoxActionType, MessageBoxEvent, DialogBoxName }
