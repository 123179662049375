import { useStore } from '@/store'
import { AppMutation } from '@/store/types'
import { getDeviceOrientation } from '@/utils'
import { computed, ref } from 'vue'

export function useViewPort(_el?: HTMLElement) {
    const store = useStore()
    const currentOrientation = ref<'portrait' | 'landscape'>()

    let _wP = 0
    let _hP = 0

    let _wL = 0
    let _hL = 0

    const initialize = () => {
        currentOrientation.value = getDeviceOrientation()
        document
            .getElementById('viewport')
            ?.setAttribute(
                'content',
                'width=' +
                    window.innerWidth +
                    ', height=' +
                    window.innerHeight +
                    ', initial-scale=1.0, maximum-scale=1.0, user-scalable=0'
            )

        if (deviceOrientation.value === 'portrait') {
            _wP = window.innerWidth
            _hP = window.innerHeight
            _wL = _hP
            _hL = _wP
        } else {
            _wL = window.innerWidth
            _hL = window.innerHeight
            _wP = _hL
            _hP = _wL
        }
    }

    const windowResize = (_elParam?: HTMLElement) => {
        const _deviceOrientation = getDeviceOrientation()
        if (deviceOrientation.value !== _deviceOrientation) {
            store.commit(AppMutation.SET_ORIENTATION, _deviceOrientation)
        }

        if (_deviceOrientation === 'landscape') {
            document
                .getElementById('viewport')
                ?.setAttribute(
                    'content',
                    'width=' +
                        _wL +
                        ', height=' +
                        _hL +
                        ', initial-scale=1.0, maximum-scale=1.0, user-scalable=0'
                )
        } else {
            document
                .getElementById('viewport')
                ?.setAttribute(
                    'content',
                    'width=' +
                        _wP +
                        ', height=' +
                        _hP +
                        ', initial-scale=1.0, maximum-scale=1.0, user-scalable=0'
                )
        }

        let bodyFonstSize = '0px'
        if (_elParam) {
            bodyFonstSize =
                (_elParam.getBoundingClientRect().width / 20 / 16 / 8) *
                    54.6134 +
                'px'
        } else {
            bodyFonstSize = (window.innerWidth / 20 / 16 / 8) * 54.6134 + 'px'
        }

        document.documentElement.style.setProperty('font-size', bodyFonstSize)
    }

    const deviceOrientation = computed(
        (): 'landscape' | 'portrait' => store.getters['deviceOrientation']
    )

    initialize()

    return {
        windowResize,
        getDeviceOrientation
    }
}
