export enum AppMutation {
    SET_ORIENTATION = 'SET_ORIENTATION',
    HIDE_LOADING = 'HIDE_LOADING',
    SHOW_LOADING = 'SHOW_LOADING',
    SET_SOUND = 'SET_SOUND',
    SET_USER_INTERACTION = 'SET_USER_INTERACTION',
    SET_LANG = 'SET_LANG',
    SET_FULL_SCREEN = 'SET_FULL_SCREEN',
    SET_VIDEO = 'SET_VIDEO',
    SET_USER = 'SET_USER',
    CLEAR_ALL = 'CLEAR_ALL',
    SET_MUSIC_VOLUME = 'SET_MUSIC_VOLUME',
    SET_CUSTOM_CHIP_VAALUE = 'SET_CUSTOM_CHIP_VAALUE',
    INIT_USER_SESSTION = 'INIT_USER_SESSTION',
    SET_COIN = 'SET_COIN',
    SET_LIMIT = 'SET_LIMIT',
    SET_CHIPS = 'SET_CHIPS',
    SET_IN_GAME_NOTICE = 'SET_IN_GAME_NOTICE',
    ADD_RESULT_TO_CACHE = 'AppMutation.ADD_RESULT_TO_CACHE',
    SET_SELECTED_CHIP = 'SET_SELECTED_CHIP',
    APP_SET_LINE = 'APP_SET_LINE',
    APP_SET_LINE_STR = 'APP_SET_LINE_STR'
}

export enum AppActions {
    FETCH_DATA = 'FETCH_DATA',
    PING_SERVER = 'PING_SERVER',
    FETCH_VIDEOS = 'FETCH_VIDEOS',
    FETCH_DATA_C_TOKEN = 'FETCH_DATA_C_TOKEN'
}
