import { createI18n } from 'vue-i18n'
import { CookiesToken } from '@/types/CookiesToken'
import { getBrowserLanguage, getCookieValue } from '.'
import gamerules from './rules'

const messages = {
    'cn': {
        serverselection: {
            lineSelection: '线路选择',
            p1: '线路为绿色',
            p2: '可直接点击进入，红色',
            p3: '代表该线路不可用，',
            p4: '如三个线路均不可用，请联系在线客服获取更多线路，并输入所获取的线路地址点击进入，即可游戏，祝您游戏愉快。',
            line1: '线路一',
            line2: '线路二',
            line3: '线路三',
            customline: '自定义线路',
            enter: '进入',
            lineerror: 'ip 地址错误'
        },
        settings: {
            language: '语言设置',
            changepassword: '修改密码',
            oldpwd: '原密码',
            newpwd: '新密码',
            confirmnewpwd: '确认新密码',
            oldpassworderror: '旧密码无效',
            newpassworderror: '新密码无效',
            passwordnotmatch: '密码不匹配',
            changepassworderror: '更改密码失败'
        },
        login: {
            submit: '登录',
            username: '账号',
            password: '密码',
            verify: '验证码',
            pleaseenterusername: '请输入用户名',
            pleaseenterpwd: '请输入密码',
            invalidcode: '验证码不正确',
            invalidparams: '参数传递错误',
            invaliduserorpassword: '用户或密码错误',
            accountislocked: '账号或上级代理被锁定',
            unknownerror: '未知错误',
            accountisalreadylogedin: '账号已经登录，请稍后尝试'
        },
        menu: {
            cutomerservice: '联系客服',
            gamerules: '游戏说明',
            changepwd: '修改密码',
            language: '语言设置',
            fullscreen: '全屏',
            exitfullscreen: '全屏',
            quit: '退出',
            dealfirst: '先发牌区',
            loby: '返回大厅',
            accounthistory: '账户历史'
        },
        baccarat: {
            name: '百家乐',
            player: '閒',
            banker: '庄',
            tie: '和',
            tiebox: '和',
            playerpair: '閒对',
            bankerpair: '庄对',
            bankerwin: '庄赢',
            playerwin: '閒赢'
        },
        dragontiger: {
            name: '龙虎',
            dragon: '龙',
            tiger: '虎',
            tie: '和',
            dragonwin: '龙赢',
            tigerwin: '虎赢'
        },
        common: {
            min: '下限',
            max: '上限',
            pairmax: '对子上限',
            tiemax: '和注上限',
            singlelimit: '单限',
            enter: '进入游戏',
            account: '账号',
            balance: '余额',
            stpbet: '停止<br/>投注',
            desk: '台号',
            chipamount: '输入筹码数量',
            pair: '对',
            confirm: '确定'
        },
        game: {
            playerask: '闲问路',
            bankerask: '庄问路',
            dragonask: '龙问路',
            tigerask: '虎问路',
            stopbetting: '停止投注',
            startbetting: '开始接受投注',
            shoeAndJu: '靴局:{0}/{1}',
            stationlimit: '本台单限',
            gameid: '局数',
            totalbet: '下注金额',
            totalwin: '赢得金额',
            confirmbet: '确定',
            cancelbet: '重设',
            changetable: '换台',
            customchip: '编辑自定义金额',
            tablelimit: '本台限红',
            chipl: '自定义'
        },
        bethistory: {
            startdate: '开始日期',
            enddate: '结束日期',
            inquire: '查询',
            date: '日期',
            transactionno: '笔数',
            totalvote: '总投',
            twin: '总赢',
            wascodebetting: '洗码费投注',
            codewashingfee: '洗码费',
            summary: '小结',
            details: '详情',
            norecordfound: '没有要显示的记录',
            gameno: '游戏编号',
            bettingtable: '投注桌台',
            gameresult: '游戏结果',
            typeofbet: '投注类型',
            betamount: '投注金额',
            winlose: '输赢',
            bettime: '投注时间'
        },
        prompt: {
            networkerror: '当前网络不佳，请重新尝试',
            invalidparams: '参数传递错误',
            wrongusernameandpassword: '用户名或密码输入错误',
            accountislocked: '账号或上级代理被锁定',
            accountisalreadylogintryagainlater: '账号已经登录，请稍后尝试',
            sessionexpired: '会话已过期',
            nobetallowed: '目前不能下注',
            selectachip: '请先选择筹码再投注',
            betsuccess: '下注成功',
            insufficientbalance: '余额不足',
            modifyok: '修改成功',
            selectbetareafirst: '请先选择要投的区域',
            betfailed: '下注失败！请重新下注!',
            exceedingbet: '超出最大限红',
            nobetyet: '你还没有投注^_^！',
            bellowlimit: '小于最低限红'
        }
    },
    'en': {
        serverselection: {
            lineSelection: 'Line selection',
            p1: 'line is green',
            p2: 'You can click directly to enter, red',
            p3: 'Indicates that the line is unavailable,',
            p4: 'If all three lines are unavailable, please contact online customer service to obtain more lines, enter the obtained line address and click to enter the game. I wish you a happy game.',
            line1: 'Line 1',
            line2: 'Line 2',
            line3: 'Line 3',
            customline: 'Enter url adress',
            enter: 'Enter',
            lineerror: 'Invalid url adress'
        },
        settings: {
            language: 'Language',
            changepassword: 'Change password',
            oldpwd: 'Old password',
            newpwd: 'New password',
            confirmnewpwd: 'Confirm password',
            oldpassworderror: 'Old password is invalid',
            newpassworderror: 'New password is invalid',
            passwordnotmatch: 'Passwords do not match',
            changepassworderror: 'Password change failed'
        },
        login: {
            submit: 'Login',
            username: 'Account',
            password: 'Password',
            verify: 'Code',
            pleaseenterusername: 'Please enter username',
            pleaseenterpwd: 'Please enter your password',
            invalidcode: 'Incorrect code',
            invalidparams: 'Invalid params',
            invaliduserorpassword: 'Invalid username or password',
            accountislocked: 'The account or parent agent is locked',
            unknownerror: 'Unknow error',
            accountisalreadylogedin:
                'The account has been logged in, please try again later'
        },
        menu: {
            cutomerservice: 'C.Service',
            gamerules: 'Game Rules',
            changepwd: 'Change Pwd',
            language: 'Language',
            fullscreen: 'Full Screen',
            exitfullscreen: 'Exit F.S',
            quit: 'Logout',
            dealfirst: 'V I P',
            loby: 'Loby',
            accounthistory: 'Act History'
        },
        baccarat: {
            name: 'BACRT',
            player: 'P',
            banker: 'B',
            tie: 'T',
            tiebox: 'Tie',
            playerpair: 'P.Pair',
            bankerpair: 'B.Pair',
            bankerwin: 'B W',
            playerwin: 'P W'
        },
        dragontiger: {
            name: 'D.Tiger',
            dragon: 'D',
            tiger: 'T',
            tie: 'T',
            dragonwin: 'D W',
            tigerwin: 'T W'
        },
        common: {
            min: 'MIN',
            max: 'MAX',
            pairmax: 'Pair Max',
            tiemax: 'Tie Max',
            singlelimit: 'Single Limit',
            enter: 'Enter',
            account: 'Act',
            balance: 'Bal',
            stpbet: 'NO<br/>BET',
            desk: 'Table No.',
            chipamount: 'Custom Amt',
            pair: 'P.r',
            confirm: 'Confirm'
        },
        game: {
            playerask: 'P.Ask',
            bankerask: 'B.Ask',
            dragonask: 'D.Ask',
            tigerask: 'T.Ask',
            stopbetting: 'Stop Betting',
            startbetting: 'Start Betting',
            shoeAndJu: '靴局:{0}/{1}',
            stationlimit: 'Station Limit',
            gameid: 'Game ID',
            totalbet: 'Total Bet',
            totalwin: 'Total Win',
            confirmbet: 'Confrim Bet',
            cancelbet: 'Cancel Bet',
            changetable: 'TBLS',
            customchip: 'Custom Chip',
            tablelimit: 'Table Limit',
            chipl: 'CUS'
        },
        bethistory: {
            startdate: 'Start Date',
            enddate: 'End Date',
            inquire: 'Submit',
            date: 'Date',
            transactionno: 'Transaction No.',
            totalvote: 'Total Vote',
            twin: 'Total Win',
            wascodebetting: 'W.C Betting',
            codewashingfee: 'C.W Fee',
            summary: 'Summary',
            details: 'Details',
            norecordfound: 'No Records',
            gameno: 'G. ID',
            bettingtable: 'Betting Table',
            gameresult: 'Game Result',
            typeofbet: 'Type Of Bet',
            betamount: 'Bet Amount',
            winlose: 'Win/Lose',
            bettime: 'Bet Time'
        },
        prompt: {
            networkerror: 'The current network is not good, please try again',
            invalidparams: 'Parameter passing error',
            wrongusernameandpassword: 'Username or password input error',
            accountislocked: 'Account or parent agent is locked',
            accountisalreadylogintryagainlater:
                'Account has been logged in, please try again later',
            sessionexpired: 'Session has expired',
            nobetallowed: 'Cannot bet at present',
            selectachip: 'Please select chips before betting',
            betsuccess: 'Bet successfully',
            insufficientbalance: 'Insufficient balance',
            modifyok: 'Modification successful',
            selectbetareafirst: 'Please select the area to bet first',
            betfailed: 'Bet failed! Please bet again!',
            exceedingbet: 'Exceeding the maximum limit',
            nobetyet: 'You haven not bet yet^_^! ',
            bellowlimit: 'Less than the minimum limit'
        }
    },
    'kor': {
        serverselection: {
            lineSelection: 'Line selection',
            p1: '라인은 녹색',
            p2: '직접 입력하려면 클릭, 빨간색',
            p3: '회선을 사용할 수 없음을 의미하며,',
            p4: '3개의 회선 중 아무 것도 사용할 수 없는 경우 온라인 고객 서비스에 연락하여 추가 회선을 확인하고 얻은 회선 주소를 입력하고 클릭하여 입력하십시오., 당신은 재생할 수 있습니다, 나는 당신에게 행복한 게임을 기원합니다.',
            line1: '1행',
            line2: '2행',
            line3: '3행',
            customline: '커스텀 라인',
            enter: '입력하다',
            lineerror: 'IP 주소 오류'
        },
        settings: {
            language: '언어 설정',
            changepassword: '비밀번호 변경',
            oldpwd: '기존 비밀번호',
            newpwd: '새 비밀번호',
            confirmnewpwd: '새 비밀번호 확인',
            oldpassworderror: '이전 비밀번호가 유효하지 않습니다.',
            newpassworderror: '새 비밀번호가 유효하지 않습니다.',
            passwordnotmatch: '비밀번호가 일치하지 않습니다',
            changepassworderror: '비밀번호 변경 실패'
        },
        login: {
            submit: '로그인',
            username: '계정',
            password: '비밀번호',
            verify: '암호',
            pleaseenterusername: '사용자 이름을 입력하십시오',
            pleaseenterpwd: '비밀번호를 입력해주세요',
            invalidcode: '잘못된 인증 코드',
            invalidparams: '잘못된 매개변수',
            invaliduserorpassword: '잘못된 사용자 이름 또는 비밀번호',
            accountislocked: '계정 또는 상위 에이전트가 잠겨 있습니다.',
            unknownerror: '알수없는 오류',
            accountisalreadylogedin:
                '계정이 로그인되었습니다. 나중에 다시 시도해 주세요.'
        },
        menu: {
            cutomerservice: '고객 서비스',
            gamerules: '게임 규칙',
            changepwd: '비밀번호 변경',
            language: '언어',
            fullscreen: '전체 화면',
            exitfullscreen: '전체 화면',
            quit: '로그아웃',
            dealfirst: 'V I P',
            loby: '로비',
            accounthistory: '계정 기록'
        },
        baccarat: {
            name: '바카라',
            player: 'P',
            banker: 'B',
            tie: 'T',
            tiebox: 'Tie',
            playerpair: 'P.Pair',
            bankerpair: 'B.Pair',
            bankerwin: 'B W',
            playerwin: 'P W'
        },
        dragontiger: {
            name: '용호',
            dragon: 'D',
            tiger: 'T',
            tie: 'T',
            dragonwin: 'D W',
            tigerwin: 'T W'
        },
        common: {
            min: '최소 베팅',
            max: '최대 베팅',
            pairmax: '페어 맥스',
            tiemax: '타이 맥스',
            singlelimit: '단일 제한',
            enter: '시작하다',
            account: '계정',
            balance: '잔액',
            stpbet: '베팅<br/>중지',
            desk: '테이블 번호',
            chipamount: '관습',
            pair: 'P.r',
            confirm: '확신하는'
        },
        game: {
            playerask: 'P.Ask',
            bankerask: 'B.Ask',
            dragonask: 'D.Ask',
            tigerask: 'T.Ask',
            stopbetting: '스톱베팅',
            startbetting: '시작 베팅',
            shoeAndJu: '靴局:{0}/{1}',
            stationlimit: '스테이션 제한',
            gameid: '게임 아이디',
            totalbet: '총 베팅',
            totalwin: '총 승리',
            confirmbet: '확인하다',
            cancelbet: '취소',
            changetable: '테이블',
            customchip: '관습',
            tablelimit: '테이블 한도',
            chipl: 'CUS'
        },
        bethistory: {
            startdate: '시작일',
            enddate: '종료일',
            inquire: '문의',
            date: '날짜',
            transactionno: '거래 번호',
            totalvote: '총 투표수',
            twin: '총 승리',
            wascodebetting: '총 롤링',
            codewashingfee: '롤링',
            summary: '요약',
            details: '세부',
            norecordfound: '표시할 기록이 없습니다.',
            gameno: '게임 번호',
            bettingtable: '베팅 테이블',
            gameresult: '게임 결과',
            typeofbet: '베팅 유형',
            betamount: '배팅 금액',
            winlose: '승/패',
            bettime: '배팅 시간'
        },
        prompt: {
            networkerror: '현재 네트워크가 좋지 않습니다. 다시 시도해 주세요.',
            invalidparams: '매개변수 전달 오류',
            wrongusernameandpassword: '사용자 이름 또는 비밀번호 입력 오류',
            accountislocked: '계정 또는 상위 에이전트가 잠겨 있습니다',
            accountisalreadylogintryagainlater:
                '계정이 로그인되었습니다. 나중에 다시 시도해 주세요',
            sessionexpired: '세션이 만료되었습니다',
            nobetallowed: '현재는 베팅할 수 없습니다',
            selectachip: '베팅하기 전에 칩을 선택하세요',
            betsuccess: '성공적인 베팅',
            insufficientbalance: '잔고가 부족함',
            modifyok: '수정 성공',
            selectbetareafirst: '먼저 투자하고 싶은 지역을 선택해주세요',
            betfailed: '베팅이 실패했습니다! 다시 베팅해주세요!',
            exceedingbet: '최대 빨간색 한도를 초과했습니다',
            nobetyet: '아직 베팅하지 않으셨습니다^_^! ',
            bellowlimit: '최저한계 미만 빨간색'
        }
    }
}

const i18n = createI18n({
    legacy: false,
    locale: getCookieValue(CookiesToken.LANG) || getBrowserLanguage(),
    fallbackLocale: 'cn',
    globalInjection: true,
    warnHtmlInMessage: false,
    warnHtmlMessage: false,
    messages
})

export default i18n
