export enum CookiesToken {
    SOUND = 'SOUND',
    LANG = 'LANG',
    VIDEO = 'VIDEO',
    USER_NAME = 'USER_NAME',
    MUSIC_VOLUME = 'MUSIC_VOLUME',
    SOUND_VOLUME = 'SOUND_VOLUME',
    CUSTOM_CHIP_VALUE = 'CUSTOM_CHIP_VALUE',
    BALANCE = 'ply001',
    LIMITS = 'ply002',
    LOBY_NOTICE = 'ply003',
    TOKEN = 'ply004',
    LIMIT = 'ply005',
    CHIPS = 'ply006',
    IN_GAME_NOTICE = 'ply007',
    SELECTED_CHIP = 'ply008',
    LINE = 'xy001',
    LINE_STR = 'xy002',
    BAC_TABLES = 'lb001',
    VIP_TABLES = 'lb002',
    DT_TABLES = 'lb003',
    XM_RATE = 'lb004'
}
